export const Logo = () => {
	return (
		<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 247 32" xmlSpace="preserve">
			<path className="st0" d="M53.7,0C45,0,38,7,38,15.7c0,8.7,7.1,15.7,15.7,15.7s15.7-7,15.7-15.7C69.5,7,62.4,0,53.7,0z M64,15.7
			c0,5.7-4.6,10.3-10.3,10.3c-5.7,0-10.3-4.6-10.3-10.3S48,5.4,53.7,5.4C59.4,5.4,64,10,64,15.7z"/>
			<path className="st1" d="M121.6,0c-8.7,0-15.7,7-15.7,15.7c0,8.7,7.1,15.7,15.7,15.7c8.7,0,15.7-7,15.7-15.7C137.3,7,130.2,0,121.6,0z
			M129.2,15.7c0,4.2-3.4,7.6-7.6,7.6c-4.2,0-7.6-3.4-7.6-7.6c0-4.2,3.4-7.6,7.6-7.6C125.7,8.1,129.2,11.5,129.2,15.7z"/>
			<path className="st0" d="M31.9,0.3h-5.4v30.1L11.1,0.3H0v30.8h5.4V1l15.4,30.1h11.1V0.3z"/>
			<path className="st0" d="M175.3,0.3h-5.4v30.1L154.5,0.3h-11.1v30.8h5.4V1l15.3,30.1h11.1V0.3z"/>
			<path className="st0" d="M97.4,0.3l-9.8,30.1L77.8,0.3h-5.7l10,30.8h10.9l10-30.8H97.4z"/>
			<path className="st0" d="M197.1,0c-8.7,0-15.7,7-15.7,15.7c0,8.7,7.1,15.7,15.7,15.7c8.7,0,15.7-7,15.7-15.7C212.8,7,205.8,0,197.1,0z
			M197.1,26c-5.7,0-10.3-4.6-10.3-10.3c0-5.7,4.6-10.3,10.3-10.3c5.7,0,10.3,4.6,10.3,10.3S202.8,26,197.1,26z"/>
			<path className="st0" d="M246.6,31.1l-10.8-9.8h0.4c5,0,9.3-3.4,10.4-7.9c0,0,0-0.1,0-0.1c0.2-0.8,0.3-1.6,0.3-2.4
			c0-5.8-4.8-10.5-10.7-10.5h-17.3v30.8h5.4v-14l15,14H246.6z M224.3,5.3h12c3.2,0,5.5,2.5,5.5,5.5c0,3.1-2.3,5.6-5.5,5.6h-12V5.3z"/>
		</svg>
	)
}
