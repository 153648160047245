import React, { FC, useCallback } from 'react';

import HeaderMenuSubmenuItem from './HeaderMenuSubmenuItem';

import { IHeaderMenuItemData } from '../HeaderMenuItem/interfaces';
import { IHeaderMenuSubmenu } from './interfaces';

import './styles.scss';

// header menu submenu
const HeaderMenuSubmenu: FC<IHeaderMenuSubmenu> = ({
  submenu,
  lang,
  open,
  queries
}) => {
  // is mobile
  const widthSubmenu = useCallback(() => {
    if (queries !== 'lg' && queries !== 'md') return '100%';

    return 320 * Math.ceil((submenu ? submenu.length : 1) / 5);
  }, [ queries, submenu ]);

  // render
  return (
    <div
      className="header-menu-submenu"
      data-active={open}
      style={{
        width: widthSubmenu()
      }}>
      {submenu && submenu.map((item: IHeaderMenuItemData, index: number) =>
        <HeaderMenuSubmenuItem
          lang={lang}
          item={item}
          key={index}
          dataKey={index} />)}
    </div>
  );
};

export default HeaderMenuSubmenu;