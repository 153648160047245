import React, { FC } from 'react';

import Icon from '../../../../Icon';
import withMediaQueries from '../../../../MediaQueries';
import Text from '../../../../Typography/Text';

import { ITimelineItemSliderItem } from './interfaces';

import { Scrollbar } from "react-scrollbars-custom";

import './styles.scss';
import { useRef } from 'react';
import UseDimension from '../../../../../uses/useDimension';

// time line item slider item
const TimelineItemSliderItem: FC<ITimelineItemSliderItem> = ({
  active,
  dir,
  item: {
    body,
    title,
    year
  },
  queries,
  last
}) => {
  const element = useRef<any>(null);
  const props = UseDimension(element);
  
  const paddingMobile = (queries === 'sm' ? 20 : 0);

  // render
  return (
    <div
      className="time-line-item-slider-item"
      data-active={active}
      data-dir={dir}
      data-last={last}
      ref={element}>
      <Text attrs={{ className: 'title' }}>
        <span className="title--container">
          <span className="year">{year}</span>
          <Icon icon="icon-text-dg" />
        </span>

        <span className="text">{title}</span>
      </Text>

      {queries === 'lg' &&
        <Text attrs={{ className: 'text' }} type="danger" text={body} />}

      {queries !== 'lg' &&
        <Scrollbar style={{ height: props.height - 110, width: props.width - paddingMobile }}>
          <Text attrs={{ className: 'text' }} type="danger" text={body} />
        </Scrollbar>}
    </div>
  );
};

export default withMediaQueries(TimelineItemSliderItem);