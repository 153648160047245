import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../../../../components/Icon';
import withMediaQueries from '../../../../../components/MediaQueries';

import { IHeaderMenuSubmenuItem } from './interfaces';

import './styles.scss';

// header menu submenu item
const HeaderMenuSubmenuItem: FC<IHeaderMenuSubmenuItem> = ({ 
  item,
  lang,
  queries,
  dataKey
}) => {
  const [ open, setOpen ] = useState<boolean>(false);

  // get link
  const getLink = useCallback((url: string = '') => {
    return url === '' ? <Link
      className="header-menu-submenu-item--link"
      to={`/${lang}/${item.meta.slug}`}>
        <span className="paragraph text">{item?.title}</span>
        <Icon icon="icon-arrow-right" />
    </Link> : <a
      className="header-menu-submenu-item--link"
      href={url}
      target="_blank"
      rel="noreferrer">
        <span className="paragraph text">{item?.title}</span>
        <Icon icon="icon-arrow-right" />
      </a>;
  }, [ item, lang ]);

  // render
  return (
    <div
      className="header-menu-submenu-item" data-key={dataKey}>
      {(queries === 'lg' || queries === 'md') && item.url_externa === '' &&
        <Link
          className="header-menu-submenu-item--link"
          to={`/${lang}/${item.meta.slug}`}>
          <span className="paragraph text">{item?.title}</span>
        </Link>}

      {(queries === 'lg' || queries === 'md') && item.url_externa !== '' &&
        <a
          className="header-menu-submenu-item--link"
          href={item?.url_externa}
          target="_blank"
          rel="noreferrer">
          <span className="paragraph text">{item?.title}</span>
        </a>}

      {(queries !== 'lg' && queries !== 'md') && <>
        {item?.submenu && item?.submenu?.length > 0 ?
          <button
            className="btn header-menu-submenu-item--link"
            onClick={() => setOpen(!open)}>
            <span className="paragraph text">{item?.title}</span>
            <Icon icon="icon-arrow-right" />
          </button> : getLink(item?.url_externa)}
        </>}
    </div>
  );
};

export default withMediaQueries(HeaderMenuSubmenuItem);