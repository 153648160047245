import React from 'react';
import { useResponsive } from 'react-hooks-responsive';

// break points
const breakpoints = { xs: 0, sm: 767, md: 1024, lg: 1140 };

// load component data
const withMediaQueries: any = (ComponentElement: any) => {
  // render
  return (props: any) => {
    const { size } = useResponsive(breakpoints);

    // render
    return(<ComponentElement
      queries={size}
      points={breakpoints}
      {...props} />);
  };
};

export default withMediaQueries;